import React  from 'react';
import { RouteMain } from './route/RouteMain';

function App() {

  return (
    <div className="App">
      <RouteMain />
    </div>
  );
}

export default App;
