import '../img/reviews/paradiseminipancake.jpg';
import '../img/reviews/lavieenjuice.jpg';
import '../img/reviews/theguesthousecollection.jpg';

export const ReviewData =[
    
//     {
//     'id': '01',
//     'owner': 'paradiseminipancakes',
//     'text': 'The best in Miami, highly recommend them !!! Thank you for our beautiful tables and excellent service ⭐️⭐️⭐️⭐️⭐️',
//     'link': 'https://www.instagram.com/p/CbJUY8fucVp/',
//     'image': 'paradiseminipancakes'
// },
{
    'id': '02',
    'owner': 'lavieenjuice',
    'text': 'Muchas Gracias por tu hermoso trabajo! 💚',
    'link': 'https://www.instagram.com/p/CYgx6BDOqqv/',
    'image': 'lavieenjuice'
},{
    'id': '03',
    'owner': 'theguesthousecollection',
    'text': 'Thank you for all of the amazing work!! 🙌🔥',
    'link': 'https://www.instagram.com/p/CYSBjdIulaa/',
    'image': 'theguesthousecollection'
},]